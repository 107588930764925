import React, { Component, ReactDOM } from "react";
// import Button from "react-bootstrap/Button";
import "./OTPINPUT.css";
class Otpinput extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: "",
            otp1: "",
            otp2: "",
            otp3: "",
            otp4: "",
            disable: true,
            mobileDevice: false,
        };
        this.handleChange = this.handleChange.bind(this);
        // this.handleSubmit = this.submitOTPHandler.bind(this);
    }

    componentDidMount = () => {
        console.log(
            /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test(
                navigator.userAgent
            )
        );
        this.setState({
            mobileDevice:
                /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test(
                    navigator.userAgent
                ),
        });
    };

    handleChange(value1, event) {
        this.setState({ [value1]: event.target.value });
    }

    submitOTPHandler = (event) => {
        const otp =
            this.state.otp1 +
            this.state.otp2 +
            this.state.otp3 +
            this.state.otp4;
        this.props.submitOTPHandler(otp);
    };

    inputfocus = (elmnt) => {
        let x = elmnt.which || elmnt.keyCode;

        console.log(x);
        // if ((x >= 48 && x <= 57) || x === 8 || x === 46 || x === 9) {
        console.log(x);
        if (elmnt.key === "Delete" || elmnt.key === "Backspace") {
            const next = elmnt.target.tabIndex - 2;
            if (next > -1) {
                elmnt.target.form.elements[next].focus();
            }
        } else if (
            ((x >= 48 && x <= 57) || x === 8 || x === 46 || x === 9) &&
            !this.state.mobileDevice
        ) {
            console.log("next");
            console.log(this.state.mobileDevice);
            console.log("desktop");
            const next = elmnt.target.tabIndex;
            if (next < 4) {
                elmnt.target.form.elements[next].focus();
            }
        } else if (this.state.mobileDevice) {
            console.log("mobile");
            const next = elmnt.target.tabIndex;
            if (next < 4) {
                elmnt.target.form.elements[next].focus();
            }
        }

        // } else {
        //     return elmnt.preventDefault();
        // }
    };

    isValidInput = (e) => {
        if (
            e.keyCode === 13 &&
            this.state.otp1 !== "" &&
            this.state.otp2 !== "" &&
            this.state.otp3 !== "" &&
            this.state.otp4 !== ""
        ) {
            this.submitOTPHandler();
        }

        let x = e.which || e.keyCode;
        return (x >= 48 && x <= 57) ||
            x === 8 ||
            (x >= 35 && x <= 40) ||
            x === 46 ||
            (x >= 96 && x <= 105) ||
            x === 9
            ? null
            : e.preventDefault();
    };

    render() {
        return (
            <form className="otp-form-container" onSubmit={this.handleSubmit}>
                <div className="otpContainer">
                    <input
                        name="otp1"
                        type="text"
                        autoComplete="off"
                        className="otpInput"
                        value={this.state.otp1}
                        onKeyPress={this.keyPressed}
                        onChange={(e) => this.handleChange("otp1", e)}
                        tabIndex="1"
                        maxLength="1"
                        onKeyUp={(e) => this.inputfocus(e)}
                        inputMode="tel"
                        autoFocus
                        onKeyDown={(e) => this.isValidInput(e)}
                    />
                    <input
                        name="otp2"
                        type="text"
                        autoComplete="off"
                        className="otpInput"
                        value={this.state.otp2}
                        onChange={(e) => this.handleChange("otp2", e)}
                        tabIndex="2"
                        maxLength="1"
                        onKeyUp={(e) => this.inputfocus(e)}
                        inputMode="tel"
                        onKeyDown={(e) => this.isValidInput(e)}
                    />
                    <input
                        name="otp3"
                        type="text"
                        autoComplete="off"
                        className="otpInput"
                        value={this.state.otp3}
                        onChange={(e) => this.handleChange("otp3", e)}
                        tabIndex="3"
                        maxLength="1"
                        onKeyUp={(e) => this.inputfocus(e)}
                        inputMode="tel"
                        onKeyDown={(e) => this.isValidInput(e)}
                    />
                    <input
                        name="otp4"
                        type="text"
                        autoComplete="off"
                        className="otpInput"
                        value={this.state.otp4}
                        onChange={(e) => this.handleChange("otp4", e)}
                        tabIndex="4"
                        maxLength="1"
                        onKeyUp={(e) => this.inputfocus(e)}
                        inputMode="tel"
                        onKeyDown={(e) => this.isValidInput(e)}
                    />
                </div>
                <div
                    className="otp-submit-buttton"
                    onClick={this.submitOTPHandler}
                >
                    <div className="otp-submit-buttton-text">Submit</div>
                </div>
            </form>
        );
    }
}

export default Otpinput;
