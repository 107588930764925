import React from "react";
import "./App.css";

import OrderTrackPage from "./Layouts/Pages/OrderTrackPage/OrderTrackPage";

function App() {
    return (
        <div className="App">
            <OrderTrackPage />
        </div>
    );
}

export default App;
