import React, { Component } from "react";
import "./OrderTrackPage.css";

import NavBar from "../../../assests/images/navbar.png";
import MobileNavBar from "../../../assests/images/mobileNavbar.png";
import MyAccount from "../../Components/MyAccount/MyAccount";
import TrackYourOrder from "../../Components/TrackYourOrder/TrackYourOrder";
import OTPVerifiedModel from "../../Components/OTPVerifiedModel/OTPVerifiedModel";
import OrdersList from "../../Components/OrdersList/OrdersList";

class OrderTrackPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            otpVerified: false,
            showOrderList: false,
            orderData: [],
        };

        this.windowOfffset = 0;
    }

    // componentDidMount = () => {
    //     console.log(
    //         /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test(
    //             navigator.userAgent
    //         )
    //     );
    // };

    showModalHandler = (e) => {
        this.setState({
            otpVerified: e,
        });
    };

    showOrderListHandler = (e, orderData) => {
        this.setState(
            {
                // showOrderList: e,
                otpVerified: e,
                orderData: orderData,
            },
            () => {
                if (this.state.otpVerified === true) {
                    // this.windowOfffset = window.scrollY;
                    document.body.setAttribute(
                        "style",
                        `position:fixed; top:-${window.scrollY}px; left:0; right:0`
                    );
                } else {
                    document.body.setAttribute("style", ` `);
                }
                setTimeout(
                    () => {
                        // alert(e, "===========");
                        if (this.state.orderData.length === 0) {
                            return this.setState(
                                {
                                    showOrderList: false,
                                    otpVerified: false,
                                },
                                () => {
                                    window.scrollTo(0, window.scrollY);
                                    document.body.setAttribute("style", ` `);
                                }
                            );
                        }
                        this.setState(
                            {
                                showOrderList: e,
                                otpVerified: false,
                            },
                            () => {
                                window.scrollTo(0, window.scrollY);
                                document.body.setAttribute("style", ` `);
                            }
                        );
                    },
                    this.state.orderData.length === 0 ? 6000 : 2000
                );
            }
        );
    };

    render() {
        return (
            <>
                {this.state.otpVerified ? (
                    <OTPVerifiedModel
                        top={window.scrollY}
                        orderDataLength={this.state.orderData.length}
                        showModalHandler={this.showModalHandler}
                    />
                ) : null}

                <div className="order-tarck-page-main-container width-100-percentage">
                    <div className="order-tarck-page-body width-100-percentage">
                        <div className="order-tarck-page-body-content">
                            {/* <div className="order-tarck-page-my-account">
                                <MyAccount />
                            </div> */}
                            {this.state.showOrderList ? (
                                <div className="order-tarck-page-order-list">
                                    <OrdersList
                                        status="delivered"
                                        orderData={this.state.orderData}
                                    />
                                    {/* <OrdersList status="order-place" /> */}
                                </div>
                            ) : (
                                <div className="order-tarck-page-track-your-order">
                                    <TrackYourOrder
                                        showOrderListHandler={
                                            this.showOrderListHandler
                                        }
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default OrderTrackPage;
