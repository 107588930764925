import React, { Component } from "react";
import "./OrdersList.css";

import { BsBoxSeam, BsCart2 } from "react-icons/bs";
import { CgTrack } from "react-icons/cg";
import { HiOutlineChevronDown, HiOutlineChevronUp } from "react-icons/hi";
import { Icon } from "@iconify/react";
import ProductImg from "../../../assests/images/Beard-Redensyl_BeardOil_300x300 1 (1).png";
import moment from "moment";

class OrdersList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showAccordion: false,
            orderData: [],
            namelenght: 18,
        };
    }

    componentDidMount = () => {
        console.log(this.props.orderData[0].shippingAddress);
        this.setState({
            orderData: this.props.orderData,
        });
    };

    showAccordionHandler = () => {
        this.setState({
            showAccordion: !this.state.showAccordion,
        });
    };

    showFullnameHandler = (nameLenght) => {
        if (this.state.namelenght === 18) {
            this.setState({
                namelenght: nameLenght,
            });
        } else {
            this.setState({
                namelenght: 18,
            });
        }
    };

    render() {
        return (
            <div className="order-list-main-container">
                <div
                    className={
                        this.props.orderData[0].status === "Cancelled" ||
                        this.props.orderData[0].status === "RTO DELIVERED"
                            ? "shipment-status shipment-status-cancelled"
                            : this.props.orderData[0].status ===
                                  "UNDELIVERED" ||
                              this.props.orderData[0].status ===
                                  "RTO initiated" ||
                              this.props.orderData[0].status ===
                                  "RTO in transit"
                            ? "shipment-status shipment-status-undelivered"
                            : "shipment-status"
                    }
                >
                    {" "}
                    {this.props.orderData[0].status === "RTO DELIVERED"
                        ? "RETURNED"
                        : this.props.orderData[0].status === "RTO in transit"
                        ? "RETURN IN TRANSIT"
                        : this.props.orderData[0].status === "RTO initiated"
                        ? "RETURN INITIATED"
                        : this.props.orderData[0].status === "UNDELIVERED"
                        ? "UNDELIVERED"
                        : this.props.orderData[0].status}
                </div>
                <div className="order-list-content">
                    <div className="order-list-header">
                        <div className="order-list-header-left">
                            <div className="box-logo">
                                <BsBoxSeam className="box-icon" />
                            </div>
                            <div className="oder-id-and-mob-no">
                                <div className="order-id">
                                    Order ID #{this.props.orderData[0].orderId}
                                </div>
                                <div className="order-mob-no">
                                    Phone number:{" "}
                                    <span>
                                        {
                                            this.props.orderData[0]
                                                .shippingAddress.phone
                                        }
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="order-list-header-right">
                            {/* {this.props.orderData[0].status !== "DELIVERED" ? ( */}
                            <a
                                href={this.props.orderData[0].trackingLink}
                                target="_blank"
                                style={{ textDecoration: "none" }}
                            >
                                <div className="order-list-shipment-container">
                                    {/* <div className="order-list-track-icon">
                                        <CgTrack className="order-list-track-logo" />
                                    </div> */}

                                    <div className="order-list-shipment-text">
                                        Track Shipment
                                    </div>
                                </div>
                            </a>
                            {/* ) : null} */}
                        </div>
                    </div>
                    <div className="order-list-header-divider-line"></div>
                    <div className="order-list-body">
                        <div className="order-list-status">
                            <div className="order-list-status-left">
                                <div className="order-list-delivery-container">
                                    {this.props.orderData[0].status ===
                                    "DELIVERED" ? (
                                        <div className="order-list-delivery-lable-div">
                                            <div className="order-list-delivery-lable">
                                                {this.props.orderData[0]
                                                    .status === "DELIVERED"
                                                    ? " Delivered on:"
                                                    : "Estimated delivery on:"}{" "}
                                            </div>
                                            <div className="order-list-deliver-date">
                                                {/* Mar 17 , 2022 */}
                                                {moment(
                                                    this.props.orderData[0]
                                                        .orderPlacedOn
                                                ).format("MMM DD , YYYY")}
                                            </div>
                                        </div>
                                    ) : null}

                                    <div className="order-list-status-lable-div">
                                        <div className="order-list-status-lable">
                                            Status:{" "}
                                        </div>
                                        {this.props.status === "delivered" ? (
                                            <div
                                                className={
                                                    this.props.orderData[0]
                                                        .status ===
                                                        "Cancelled" ||
                                                    this.props.orderData[0]
                                                        .status ===
                                                        "RTO DELIVERED"
                                                        ? "order-list-status-text order-list-status-text-cancelled"
                                                        : this.props
                                                              .orderData[0]
                                                              .status ===
                                                              "UNDELIVERED" ||
                                                          this.props
                                                              .orderData[0]
                                                              .status ===
                                                              "RTO initiated" ||
                                                          this.props
                                                              .orderData[0]
                                                              .status ===
                                                              "RTO in transit"
                                                        ? "shipment-status shipment-status-undelivered"
                                                        : "order-list-status-text"
                                                }
                                            >
                                                {this.props.orderData[0]
                                                    .status === "RTO DELIVERED"
                                                    ? "Returned"
                                                    : this.props.orderData[0]
                                                          .status ===
                                                      "RTO in transit"
                                                    ? "Return in transit"
                                                    : this.props.orderData[0]
                                                          .status ===
                                                      "RTO initiated"
                                                    ? "Return initiated"
                                                    : this.props.orderData[0]
                                                          .status ===
                                                      "UNDELIVERED"
                                                    ? "Undelivered"
                                                    : this.props.orderData[0]
                                                          .status}
                                            </div>
                                        ) : this.props.status ===
                                          "order-place" ? (
                                            <div className="order-list-status-text order-placed">
                                                Order Placed
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                            <div className="order-list-status-right">
                                {/* {this.props.orderData[0].status !==
                                "DELIVERED" ? ( */}
                                <div className="order-list-shipment-mobile-container">
                                    <div className="order-list-shipment-mobile-content">
                                        {/* <div className="order-list-track-icon">
                                                <CgTrack className="order-list-track-logo" />
                                            </div> */}
                                        <a
                                            href={
                                                this.props.orderData[0]
                                                    .trackingLink
                                            }
                                            target="_blank"
                                        >
                                            <div className="order-list-shipment-text">
                                                Track Shipment
                                            </div>
                                        </a>
                                    </div>
                                </div>
                                {/* ) : null} */}
                                <div
                                    className="order-list-order-details"
                                    onClick={this.showAccordionHandler}
                                >
                                    <BsCart2 className="order-list-cart-icon" />
                                    <div className="order-list-details-text">
                                        Order details
                                    </div>
                                    {this.state.showAccordion ? (
                                        <HiOutlineChevronUp className="order-list-down-icon" />
                                    ) : (
                                        <HiOutlineChevronDown className="order-list-down-icon" />
                                    )}
                                </div>
                            </div>
                        </div>
                        {this.state.showAccordion ? (
                            <div className="order-list-details-accordion">
                                <div className="order-list-details-header">
                                    <div className="order-list-details-header-left">
                                        <div className="cart-logo">
                                            <BsCart2 className="cart-icon" />
                                        </div>
                                        <div className="oder-id-and-mob-no">
                                            <div className="order-id">
                                                Order Details
                                            </div>
                                            <div className="order-mob-no">
                                                Order placed on:{" "}
                                                <span>
                                                    {moment(
                                                        this.props.orderData[0]
                                                            .orderPlacedOn
                                                    ).format("MMM DD , YYYY")}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="order-date-and-payment">
                                    <div className="order-placed-container">
                                        <div className="order-placed-lable">
                                            Order placed on:
                                        </div>
                                        <div className="order-placed-date">
                                            {moment(
                                                this.props.orderData[0]
                                                    .orderPlacedOn
                                            ).format("MMM DD , YYYY")}
                                        </div>
                                    </div>
                                    <div className="order-payment order-placed-container">
                                        <div className="order-payment-lable order-placed-lable">
                                            Payment method:
                                        </div>
                                        <div className="order-payment-method order-placed-date">
                                            {
                                                this.props.orderData[0]
                                                    .paymentMethod
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="products-list-container">
                                    <div className="products-list-title">
                                        Order items:
                                    </div>
                                    <div className="list-of-products">
                                        {this.props.orderData[0].products.map(
                                            (item, index) => {
                                                return (
                                                    <div
                                                        key={index}
                                                        className="list-of-products-row"
                                                    >
                                                        <div className="list-of-products-row-left">
                                                            <div className="products-photo">
                                                                <img
                                                                    src={
                                                                        item.icon
                                                                    }
                                                                    alt="product imege"
                                                                />
                                                            </div>
                                                            <div className="product-info">
                                                                <div className="product-info-name">
                                                                    {item.name}
                                                                </div>
                                                                <div
                                                                    className="product-info-name product-info-name-mob"
                                                                    onClick={() => {
                                                                        this.showFullnameHandler(
                                                                            item
                                                                                .name
                                                                                .length
                                                                        );
                                                                    }}
                                                                >
                                                                    {item.name.substring(
                                                                        0,
                                                                        this
                                                                            .state
                                                                            .namelenght
                                                                    )}
                                                                    {this.state
                                                                        .namelenght ===
                                                                    18 ? (
                                                                        <span>
                                                                            ...
                                                                        </span>
                                                                    ) : null}
                                                                </div>
                                                                <div className="product-info-quantity">
                                                                    Qty:{" "}
                                                                    {
                                                                        item.quantity
                                                                    }{" "}
                                                                    {/* | SKU:{" "}
                                                                    {item.SKU} */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="list-of-products-row-right">
                                                            <div className="product-info-price">
                                                                Rs. {item.price}
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            }
                                        )}
                                    </div>
                                </div>
                                <div className="shipment-details-container">
                                    <div className="tax-and-price">
                                        <div className="tax-and-price-row">
                                            <div className="tax-and-price-row-label">
                                                Subtotal
                                            </div>
                                            <div className="tax-and-price-row-amount">
                                                Rs.{" "}
                                                {
                                                    this.props.orderData[0]
                                                        .invoiceDetails.subTotal
                                                }
                                            </div>
                                        </div>
                                        <div className="tax-and-price-row">
                                            <div className="tax-and-price-row-label">
                                                Discount
                                                {this.props.orderData[0]
                                                    .invoiceDetails
                                                    .discountCode !== ""
                                                    ? `(${this.props.orderData[0].invoiceDetails.discountCode})`
                                                    : null}
                                            </div>
                                            <div className="tax-and-price-row-amount tax-and-price-row-discount-amount">
                                                -Rs.{" "}
                                                {
                                                    this.props.orderData[0]
                                                        .invoiceDetails.discount
                                                }
                                            </div>
                                        </div>
                                        <div className="tax-and-price-row">
                                            <div className="tax-and-price-row-label">
                                                Shipping(
                                                {
                                                    this.props.orderData[0]
                                                        .shipping.shippingMethod
                                                }
                                                )
                                            </div>
                                            <div className="tax-and-price-row-amount">
                                                Rs.{" "}
                                                {
                                                    this.props.orderData[0]
                                                        .shipping.shippingPrice
                                                }
                                            </div>
                                        </div>
                                        <div className="tax-and-price-row">
                                            <div className="tax-and-price-row-label">
                                                TAX
                                            </div>
                                            <div className="tax-and-price-row-amount">
                                                Rs.{" "}
                                                {
                                                    this.props.orderData[0]
                                                        .invoiceDetails.tax
                                                }
                                            </div>
                                        </div>
                                        <div className="tax-and-price-row">
                                            <div className="tax-and-price-row-label">
                                                Total
                                            </div>
                                            <div className="tax-and-price-row-amount">
                                                Rs.{" "}
                                                {
                                                    this.props.orderData[0]
                                                        .invoiceDetails
                                                        .totalPrice
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="billing-address-container">
                                        <div className="billing-address-container-left">
                                            <div className="billing-address-label">
                                                Billing address
                                            </div>

                                            <div className="user-details">
                                                <div className="user-details-name">
                                                    {
                                                        this.props.orderData[0]
                                                            .billingAddress.name
                                                    }
                                                </div>

                                                <div className="user-details-name  user-details-name">
                                                    {
                                                        this.props.orderData[0]
                                                            .billingAddress
                                                            .address1
                                                    }
                                                </div>
                                            </div>
                                            <div className="payment-status">
                                                <div className="payment-status-label">
                                                    Payment status:
                                                </div>
                                                <div className="payment-status-value">
                                                    {
                                                        this.props.orderData[0]
                                                            .paymentMethod
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="billing-address-container-left">
                                            <div className="billing-address-label">
                                                Shipping address
                                            </div>

                                            <div className="user-details">
                                                <div className="user-details-name">
                                                    {
                                                        this.props.orderData[0]
                                                            .shippingAddress
                                                            .name
                                                    }
                                                </div>

                                                <div className="user-details-name  user-details-name">
                                                    {
                                                        this.props.orderData[0]
                                                            .shippingAddress
                                                            .address1
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="chat-with-us-container">
                                        <div className="chat-with-us-content">
                                            <div className="chat-with-us-text">
                                                Having issues with an order?
                                            </div>
                                            <a
                                                href="http://wa.me/+919154703345"
                                                style={{
                                                    textDecoration: "none",
                                                }}
                                            >
                                                <div className="chat-with-us-button">
                                                    <div className="chat-with-us-button-text">
                                                        Chat with us
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
        );
    }
}

export default OrdersList;
