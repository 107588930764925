import React, { Component } from "react";
import "./MyAccount.css";

import { CgTrack } from "react-icons/cg";
import { BsCart2 } from "react-icons/bs";
import { BiSupport, BiLogOut } from "react-icons/bi";
import { RiCoupon3Line } from "react-icons/ri";
import { HiOutlineChevronDown } from "react-icons/hi";

class MyAccount extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    render() {
        return (
            <div className="my-account-main-container">
                <div className="my-account-content">
                    <div className="my-account-main-container-title">
                        <div className="my-account-main-container-title-text">
                            My Account
                        </div>
                    </div>
                    <div className="my-account-main-container-content">
                        <div className="my-account-main-container-content-track-order active-account">
                            <div className="my-account-main-container-content-track-icon">
                                <CgTrack className="my-accounts-icon" />
                            </div>
                            <div className="my-account-main-container-content-track-text">
                                Track my order
                            </div>
                        </div>
                        <div className="my-account-main-container-content-track-order">
                            <div className="my-account-main-container-content-track-icon">
                                <BsCart2 className="my-accounts-icon" />
                            </div>
                            <div className="my-account-main-container-content-track-text">
                                My orders
                            </div>
                        </div>
                        <div className="my-account-main-container-content-track-order">
                            <div className="my-account-main-container-content-track-icon">
                                <BiSupport className="my-accounts-icon" />
                            </div>
                            <div className="my-account-main-container-content-track-text">
                                Support and help
                            </div>
                            <div className="my-account-main-container-content-track-left-icon">
                                <HiOutlineChevronDown className="my-accounts-icon" />
                            </div>
                        </div>
                        <div className="my-account-main-container-content-track-order">
                            <div className="my-account-main-container-content-track-icon">
                                <RiCoupon3Line className="my-accounts-icon" />
                            </div>
                            <div className="my-account-main-container-content-track-text">
                                My Referrals
                            </div>
                        </div>
                        <div className="my-account-main-container-content-track-order">
                            <div className="my-account-main-container-content-track-icon">
                                <BiLogOut className="my-accounts-icon logout-icon" />
                            </div>
                            <div className="my-account-main-container-content-track-text logout-text">
                                Log Out
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default MyAccount;
