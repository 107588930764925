import React, { Component } from "react";
import "./TrackYourOrder.css";

import validator from "validator";
import axios from "axios";
import OtpInput from "react-otp-input";
import OTPInput, { ResendOTP } from "otp-input-react";
// import Otpinput from "../OTPINPUT/OTPINPUT";
import Otpinput from "../../Pages/OTPINPUT/OTPINPUT";

class TrackYourOrder extends Component {
    constructor(props) {
        super(props);

        this.state = {
            mobileNo: "",
            orderId: "",
            tabIndex: null,
            showOTPInputFields: false,
            showInValidMobileError: false,
            inValidMobileErrorMesssage:
                "There are no orders linked to this number",
            showInvalidOTPError: false,
            showInvalidOrderIdmessage: false,
            showResendMessage: false,
            disabledOTPButton: false,
        };
    }

    inputHandler = (e) => {
        this.setState(
            {
                [e.target.name]: e.target.value,
            },
            () => {
                if (
                    this.state.mobileNo.length === 10 &&
                    this.state.orderId.length > 0
                ) {
                    this.setState({
                        disabledOTPButton: true,
                    });
                } else {
                    this.setState({
                        disabledOTPButton: false,
                    });
                }
            }
        );
        if (this.state.orderId.length >= 0) {
            this.setState({
                showInvalidOrderIdmessage: false,
            });
        }
        if (this.state.mobileNo.length <= 10) {
            this.setState({
                showOTPInputFields: false,
                showInValidMobileError: false,
            });
        }
    };

    submitOTPHandler = (otp) => {
        console.log(otp, this.state.mobileNo);
        axios
            .post(
                "https://stagingappapi.ghc.health/api/shopifyordersv3/verifyOTP",
                // "http://staging-01.ap-south-1.elasticbeanstalk.com/api/shopifyordersv3/verifyOTP",
                { otp: otp, phone: this.state.mobileNo }
            )
            .then((res) => {
                console.log(res);
                if (res.data.results.type === "success") {
                    axios
                        .post(
                            "https://stagingappapi.ghc.health/api/shopifyordersv3/orderDetails",
                            // "http://staging-01.ap-south-1.elasticbeanstalk.com/api/shopifyordersv3/orderDetails",
                            // {
                            //     phone: "6309420633", // will be replaced with this.state.mobileNo
                            //     orderId: 335341, // will be replaced with this.state.orderId
                            // }
                            {
                                // phone: "9884682331",
                                phone: this.state.mobileNo,
                                orderId: this.state.orderId,
                                // phone: "9154460935",
                                // orderId: 311655,
                                // phone: "8979280102",
                                // orderId: 339432,
                            }
                        )
                        .then((response) => {
                            console.log(response);
                            if (response.status === 200) {
                                this.props.showOrderListHandler(
                                    true,
                                    response.data.response
                                );
                                if (response.data.response.length === 0) {
                                    this.setState({
                                        mobileNo: "",
                                        orderId: "",
                                        showOTPInputFields: false,
                                    });
                                }
                            }
                        });
                } else {
                    this.setState({
                        showInvalidOTPError: true,
                    });
                }
            });
    };

    mobileNoVerificationHandler = () => {
        console.log(this.state.mobileNo, "++++++++", this.state.orderId);
        if (this.state.orderId === "") {
            return this.setState({
                showInvalidOrderIdmessage: true,
            });
        } else if (validator.isMobilePhone(this.state.mobileNo, ["en-IN"])) {
            axios
                .post(
                    "https://stagingappapi.ghc.health/api/shopifyordersv3/sendOTP",
                    {
                        phone: this.state.mobileNo,
                    }
                )
                .then((res) => {
                    console.log(res);
                    if (res.status === 200) {
                        this.setState({
                            showOTPInputFields: true,
                        });
                    }
                });
        } else {
            this.setState({
                showInValidMobileError: true,
                inValidMobileErrorMesssage: "Invalid mobile number!",
            });
        }
    };

    resendOTPHandler = () => {
        this.setState(
            {
                showInvalidOTPError: false,
                showOTPInputFields: false,
            },
            () => {
                axios
                    .post(
                        "https://stagingappapi.ghc.health/api/shopifyordersv3/resendOTP",
                        // "http://staging-01.ap-south-1.elasticbeanstalk.com/api/shopifyordersv3/resendOTP",
                        { phone: this.state.mobileNo }
                    )
                    .then((res) => {
                        console.log(res);
                        if (res.data.results.type === "success") {
                            this.setState({
                                showResendMessage: true,
                                showOTPInputFields: true,
                            });
                        }
                        setTimeout(() => {
                            this.setState({
                                showResendMessage: false,
                            });
                        }, 3000);
                    });
            }
        );
    };

    isValidInput = (e, inputField) => {
        if (e.keyCode === 13 && inputField === "mobile") {
            this.mobileNoVerificationHandler();
        }

        let x = e.which || e.keyCode;
        return (x >= 48 && x <= 57) ||
            x === 8 ||
            (x >= 35 && x <= 40) ||
            x === 46 ||
            (x >= 96 && x <= 105) ||
            x === 9
            ? null
            : e.preventDefault();
    };

    render() {
        return (
            <div className="track-your-order-main-container">
                <div className="track-your-order-content">
                    <div className="track-your-order-title">
                        <div className="track-your-order-title-text">
                            Track your Order
                        </div>
                    </div>
                    <div className="track-your-order-body">
                        <div className="track-your-order-body-left">
                            <div className="phone-validation-container">
                                <div>
                                    <div className="phone-validation-header">
                                        Order ID{" "}
                                        <span className="staric-input">*</span>
                                    </div>
                                    <div className="phone-validation-input">
                                        <input
                                            type="text"
                                            placeholder="Enter your Order ID"
                                            name="orderId"
                                            value={this.state.orderId}
                                            onChange={this.inputHandler}
                                            maxLength="10"
                                            inputMode="tel"
                                            pattern="[0-9]*"
                                            onKeyDown={(e) =>
                                                this.isValidInput(e, "orderId")
                                            }
                                        />
                                    </div>
                                    {this.state.showInvalidOrderIdmessage ? (
                                        <div className="invalid-mob-no">
                                            Please enter valid Order ID.
                                        </div>
                                    ) : null}
                                </div>
                                <div className="mobile-inuput-div">
                                    {" "}
                                    <div className="phone-validation-header">
                                        Phone number used while placing an order{" "}
                                        <span className="staric-input">*</span>
                                    </div>
                                    <div className="phone-validation-input">
                                        <input
                                            type="text"
                                            placeholder="Enter your phone number"
                                            name="mobileNo"
                                            value={this.state.mobileNo}
                                            onChange={this.inputHandler}
                                            maxLength="10"
                                            inputMode="tel"
                                            pattern="[0-9]*"
                                            onKeyDown={(e) =>
                                                this.isValidInput(e, "mobile")
                                            }
                                        />
                                        {/* No linked no message */}
                                    </div>
                                    {this.state.showInValidMobileError ? (
                                        <div className="invalid-mob-no">
                                            {
                                                this.state
                                                    .inValidMobileErrorMesssage
                                            }
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            {!this.state.showOTPInputFields ? (
                                <div className="opt-button-container">
                                    <div
                                        className={
                                            this.state.disabledOTPButton ===
                                            false
                                                ? "otp-buttton otp-buttton-disable"
                                                : "otp-buttton"
                                        }
                                        onClick={
                                            this.state.disabledOTPButton ===
                                            true
                                                ? this
                                                      .mobileNoVerificationHandler
                                                : null
                                        }
                                    >
                                        <div className="otp-buttton-text">
                                            Send OTP
                                        </div>
                                    </div>
                                </div>
                            ) : null}
                        </div>
                        {this.state.showOTPInputFields ? (
                            <div className="track-your-order-body-right">
                                <div className="otp-input-container">
                                    <div className="otp-title-text">
                                        Please enter the OTP sent to your phone
                                        number
                                    </div>
                                    <div className="otp-input-content">
                                        <div className="otp-inputs-container">
                                            <Otpinput
                                                submitOTPHandler={
                                                    this.submitOTPHandler
                                                }
                                            />
                                        </div>
                                    </div>

                                    {this.state.showInvalidOTPError ? (
                                        <div
                                            className="invalid-otp-text"
                                            style={{ color: "#f44336" }}
                                        >
                                            Invalid OTP. Please try again
                                        </div>
                                    ) : null}
                                    <div
                                        className="resend-otp-button"
                                        onClick={this.resendOTPHandler}
                                    >
                                        Resend OTP
                                    </div>
                                </div>
                            </div>
                        ) : null}
                    </div>
                </div>
                {this.state.showResendMessage ? (
                    <div className="otp-resend">OTP sent</div>
                ) : null}
            </div>
        );
    }
}

export default TrackYourOrder;
