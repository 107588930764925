import React, { Component } from "react";
import "./OTPVerifiedModel.css";

import ModelLogo from "../../../assests/images/model-logo.png";
import { Icon } from "@iconify/react";

class OTPVerifiedModel extends Component {
    constructor(props) {
        super(props);

        this.state = {
            otp: false,
            orderError: false,
        };
    }

    render() {
        return (
            <div
                className="otp-model-main-container"
                style={{ top: `${this.props.top}px` }}
                onClick={() => this.props.showModalHandler(false)}
            >
                <div className="otp-model-content">
                    {this.props.orderDataLength > 0 ? (
                        <div className="model-logo-div">
                            <Icon
                                icon="icon-park-solid:success"
                                className="success-icon"
                            />
                        </div>
                    ) : null}
                    {this.props.orderDataLength === 0 ? (
                        <div className="model-logo-div">
                            <Icon
                                icon="bxs:binoculars"
                                className="binoculars-icon"
                            />
                        </div>
                    ) : null}

                    {this.props.orderDataLength > 0 ? (
                        <div className="opt-verified-text">
                            OTP Verifed Succesfully!
                        </div>
                    ) : null}

                    {this.props.orderDataLength === 0 ? (
                        <div className="order-error-text">
                            You have entered incorrect order details, if you
                            have placed this order recently, please come back
                            after a few minutes to check the status.
                        </div>
                    ) : null}
                </div>
            </div>
        );
    }
}

export default OTPVerifiedModel;
